
<template>
    <canvas :width="width" :height="height" :id="id"></canvas>
</template>

<script>
import Chart from 'chart.js';
export default {
  props: ['id','width','height','type','title','labels','data', 'fill', 'backgroundColor', 'borderColor', 'borderWidth'],
  mounted() {
    const ctx = document.getElementById(this.id).getContext('2d');
    new Chart(ctx, {
      type: this.type
        ? this.type
        : 'bar',
      data: {
        labels: this.labels,
        datasets: [{
          label: this.title,
          data: this.data,
          fill: this.fill,
          backgroundColor: this.backgroundColor,
          borderColor: this.borderColor,
          borderWidth: this.borderWidth
            ? this.borderWidth
            : 1
        }]
      },
      options: {
        annotation: {
          drawTime: 'afterDatasetsDraw',
          events: ['click'],
          annotations: [{
            id: 'hline',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: 5,
            borderColor: 'black',
            borderWidth: 5,
            label: {
              backgroundColor: 'red',
              content: 'Test Label',
              enabled: true
            },
          }, {
            id: 'vline',
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: 'May',
            borderColor: 'black',
            borderWidth: 5,
            label: {
              backgroundColor: 'red',
              content: 'Test Label',
              enabled: true
            },
          }, {
            id: 'vline-rotated',
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: 'June',
            borderColor: 'black',
            borderWidth: 5,
            label: {
              backgroundColor: 'red',
              content: 'Test Label',
              enabled: true,
              rotation: -90
            },
							
          }, {
            drawTime: 'beforeDatasetsDraw',
            type: 'box',
            xScaleID: 'x-axis-0',
            yScaleID: 'y-axis-0',
            xMin: 'February',
            xMax: 'April',
            yMin: 4,
            yMax: 4,
            backgroundColor: 'rgba(101, 33, 171, 0.5)',
            borderColor: 'rgb(101, 33, 171)',
            borderWidth: 1,
							
          }]
        }
      }
    });
  }
};
</script>